.quote-retweet {
  border: 1px solid #38444d;
  border-radius: 15px;
  padding: 15px;
  margin-top: 10px;
  cursor: pointer;
}

.quote-retweet-header {
  display: flex;
  gap: 1%;
}

.quote-retweet-display-name {
  font-weight: 500;
}

.quote-retweet-display-name, .quote-retweet-username {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.quote-retweet-username, .quote-retweet-time-passed {
  color: #8b98a5;
}

.quote-retweet-content p {
  margin: 0;
  margin-top: 5px;
}