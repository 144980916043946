.App {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #15202b;
  color: #fff;
}

.body-link {
  color: #1d9bf0;
}

#left-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 33vw;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 1.4rem;
}

#menu-logo-full {
  padding: 10px 0px;
  width: 260px;
  display: flex;
  align-items: center;
}

#menu-logo {
  height: auto;
  width: auto;
  box-sizing: border-box;
}

.dias {
  display: flex;
  align-items: center;
  gap: 0;
}

.dia-icon.right {
  margin-left: -33%;
}

#menu-logo-text {
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 900;
}

#top-area , #bottom-area {
  padding-right: 10px;
}

#left-menu-nav {
  width: 260px;
}

.menu-link {
  display: flex;
  align-items: center;
  margin: 5px 0px;
  cursor: pointer;
  padding: 15px 25px;
  transition: background-color 0.2s;
  border-radius: 31px;
}

.menu-link:hover {
  background-color: #f7f9f91a;
}

.menu-link-icon {
  display: inline-block;
  font-size: 1.7rem;
  width: 32px;
  height: 32px;
}

.menu-link-name {
  display: inline-block;
  margin-left: 20px;
}

#bottom-area {
  padding-bottom: 20px;
}

#user-area {
  width: 210px;
  cursor: default;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  padding: 15px 25px;
  transition: background-color 0.2s;
  border-radius: 31px;
}

#user-area:hover {
  background-color: #f7f9f91a;
}

#user-area-info {
  display: flex;
  flex-flow: column nowrap;
  gap: 3px;
}

#user-area-name {
  font-weight: 600;
}

#user-area-username {
  color: #788b9c;
}

#content {
  width: 33vw;
  margin-left: 33vw;
  min-height: 100%;
  border: 1px solid #38444d;
  border-bottom: none;
}

#content-header, #content-header-mobile {
  background-color: #15202baa;
  backdrop-filter: blur(0.2);
  padding: 15px;
  padding-bottom: 30px;
  border-bottom: 1px solid #38444d;
}

#content-header-mobile {
  display: none;
}

#content-header h2, #content-header-mobile h2 {
  margin: 0;
}

#right-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 33vw;
  padding-left: 10px;
}

.right-section {
  background-color: #1e2732;
  width: 50%;
  border-radius: 30px;
  padding: 15px;
  margin-top: 15px;
}

.right-section h3 {
  margin: 0;
  padding: 15px;
  font-size: 1.6rem;
  font-weight: 700;
}

.current-trend {
  padding: 20px 10px;
}

.trend-name {
  font-weight: 600;
  font-size: 1.2rem;
}

.trend-count {
  color: #788b9c;
}

@media (max-width: 1200px) {
  .menu-link-name, #menu-logo-text, #user-area-info {
    display: none;
  }

  #left-menu {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    width: 10vw;
  }

  #menu-logo-full {
    width: 34px;
  }

  #top-area {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  #left-menu-nav, #user-area {
    width: auto;
  }

  #content {
    margin-left: 10vw;
    width: 55vw;
  }
}

@media (max-width: 810px) {
  #right-menu {
    display: none;
  }

  #content {
    width: 90vw;
  }
}

@media (max-width: 500px) {
  .App {
    position: relative;
  }

  #left-menu {
    z-index: 3;
    position: relative;
    display: block;
    width: 100%;
    height: 10vh;
    position: fixed;
    top: 90%;
    background-color: #15202b;
    border-top: 1px solid #38444d;
  }

  #menu-logo-full {
    display: none;
  }

  #left-menu-nav {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 2rem;
  }

  #left-menu-nav > .menu-link-icon:not(:nth-last-of-type(-n+2)) {
    display: none;
  }

  #menu-link-bookmarks, #menu-link-profile {
    display: none;
  }

  #content {
    position: absolute;
    top: 0;
    height: 90vh;
    max-height: 90vh;
    min-height: 90vh;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
  }

  #content-header {
    display: none;
  }

  #content-header-mobile {
    display: block;
    padding: 10px 15px;
  }

  #content-header-home {
    display: flex;
    align-items: center;
    gap: 25%;
    color: #1d9bf0;
  }

  #post-editor, #bottom-area {
    display: none !important;
  }
}